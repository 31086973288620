
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBS45qi2sbXRQNlmrRXUrSFRV-0LYe0Bxw",
  authDomain: "evote-fc5db.firebaseapp.com",
  projectId: "evote-fc5db",
  storageBucket: "evote-fc5db.appspot.com",
  messagingSenderId: "131612150488",
  appId: "1:131612150488:web:3ad726d50c0ee7a79d65ad"
};

const app = initializeApp(firebaseConfig);