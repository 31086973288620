import { app } from "../Firebase/auth"

import { useState,useEffect } from "react";
import { doc, setDoc,getFirestore, addDoc,collection ,updateDoc} from "firebase/firestore"; 
import swal from 'sweetalert'
import { Link,useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword,createUserWithEmailAndPassword , onAuthStateChanged,signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
const Register = () =>{

    let navigate = useNavigate();
    const auth = getAuth();
    
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [aadharno,setaadharno] = useState('');
    const [psw,setPsw] = useState('');
    const db=getFirestore();


    const handleRegister = (e) =>{
        e.preventDefault();
        const authentication = getAuth();
        createUserWithEmailAndPassword(authentication, email, psw)
            .then((response) => {
                const ref= collection(db, "Users")
                addDoc(ref,{
                    Name: name,
                    Email: email,
                    Password: psw,
                    Aadhar_No:aadharno,
                })
                swal({
                    title: "Sign up Succcesfully",
                    text: "Welcome",
                    icon: "success",
                    button: "Okay",
                  });
                  navigate(`/dashboard`);
                
          })
          .catch((error) => {
                    
                    swal({
                        title: "Failed to sign Up",
                        text: "please try again",
                        icon: "error",
                        button: "Okay",
                      });
           
        });
        setName("");
        setPsw("");
        setaadharno("");
        setEmail("");
          
    }
    return(<>
        <div className="container mt-4 text-center page-bg">
            <p className="h4 pl-4 white">Sign Up</p>
            {/* <CheckUser/> */}
            <form className="center loginform" onSubmit={handleRegister}>
            <div className="form-floating mb-3">
                <input type="email" className="form-control" id="floatingInput" placeholder="abc"
                value={email}
                onChange={(e)=> setEmail(e.target.value)}
                />
                <label htmlFor="floatingInput">Email</label>
            </div>
            <div className="form-floating mb-3">
                <input type="name" className="form-control" id="floatingInput" placeholder="abc"
                value={name}
                onChange={(e)=> setName(e.target.value)}
                />
                <label htmlFor="floatingInput">Name</label>
            </div>
            <div className="form-floating mb-3">
                <input type="name" className="form-control" id="floatingInput" placeholder="abc"
                value={aadharno}
                onChange={(e)=> setaadharno(e.target.value)}
                />
                <label htmlFor="floatingInput">Aadhar No</label>
            </div>
            <div className="form-floating mb-3">
                <input type="password" className="form-control" id="floatingInput" placeholder="abc"
                value={psw}
                onChange={(e)=> setPsw(e.target.value)}
                />
                <label htmlFor="floatingInput">Password</label>
            </div>
            <button type="submit" className="btn btn-primary" >Register</button>
            <p className="white">Already have an account?
            <Link to="/login" className="white"> Log In</Link></p>
        </form>
        </div>
    </>)

}
export default Register;