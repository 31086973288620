// import CheckUser from "./check.admin"
import { useState,useEffect } from "react";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged,sendPasswordResetEmail,signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import swal from 'sweetalert';
import { app } from "../Firebase/auth"
import { Link,useNavigate } from "react-router-dom";

const Forgot = () =>{
    const [email, setEmail] = useState('');

    let navigate = useNavigate();
    const auth = getAuth();

// function for forgot password
    const handleForgot = (e) =>{
        e.preventDefault();
        sendPasswordResetEmail(auth, email)
            .then((response) => {
               
                swal({
                    title: "Please check your email inbox",
                    text: "We've sent you a email",
                    icon: "success",
                    button: "Okay",
                  });
                  navigate(`/`);
                
          })
          .catch((error) => {
                    swal({
                        title: "Failed to sent password reset link",
                        text: "please try again",
                        icon: "error",
                        button: "Okay",
                      });           
        });
        setEmail("");
    }
    return(<>
        <div className="container text-center page-bg">
            <p className="h4 pl-4 white">Forgot Password</p>
            <form className="center loginform" onSubmit={handleForgot}>
            <div className="form-floating mb-3">
                <input type="email" className="form-control" id="floatingInput" placeholder="abc"
                value={email} required
                onChange={(e)=> setEmail(e.target.value)}
                />
                <label htmlFor="floatingInput">Email Address</label>
            </div>
            <button type="submit" className="btn btn-primary" >Submit</button>
            <p className="white">Don't have an account?
            <Link to="/register" className="white"> Sign up</Link></p>

            <Link to="/login" className="white"> Log In</Link>
        </form>
        </div>
    </>)

}
export default Forgot;