import { useState,useEffect } from "react";
import { getAuth, signInWithEmailAndPassword,signOut, onAuthStateChanged,signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import swal from 'sweetalert';
import { app } from "../Firebase/auth"
import { Link,useNavigate } from "react-router-dom";
import { doc, setDoc,onSnapshot,getFirestore,getDocs, addDoc,collection ,updateDoc, query, where} from "firebase/firestore"; 


import bjp from "../Assets/bjp.png"
import ncp from "../Assets/ncp.png"
import inc from "../Assets/inc.jpeg"
import aap from "../Assets/aap.jpeg"



const Dashboard =() =>{
    let navigate = useNavigate();
    let [counts, setCounts] = useState([]);
    const [num, setNum] = useState([]);

    const auth = getAuth();
    const user = auth.currentUser; 


    //used to check user logged in or not
    onAuthStateChanged(auth, (user) => {
        if (user) {
            
        } else {
            swal({
                title: "Buddy,login first",
                text: "Please login",
                icon: "warning",
                button: "Okay",
              });
            navigate(`/`);
        }
      });


    //function for signOut
      const handleSignOut = ()=>{
        signOut(auth).then(() => {
            swal({
                title: "Sign Out Succcesfully",
                icon: "success",
                button: "Okay",
              });
              navigate(`/`);
        }).catch((error) => {
  
        });
      }

      const [part1, setParty1] = useState(''); //selected party
      const [aadhar, setAadhar] = useState(''); //get user entered aadhar num
      const db=getFirestore();

      // party vote counts
      let Count_1;
      let Count_2;
      let Count_3;
      let Count_4;
      let Count_o;

// function to handleVote
      const handleVote = (e) =>{
        e.preventDefault();
        const db=getFirestore();

        const ref1= collection(db, "Users");

        const getData =async ()=>{
            const data= await getDocs(ref1)
            .then((snapshot)=>{
                let data_no=[];
                snapshot.docs.forEach((doc)=>{
                    data_no.push({...doc.data(),id:doc.id})
                    // console.table(data_no)
                })
                let temp_num=[];
                data_no.map(a_num => (
                   temp_num.push(a_num.Aadhar_No)
                  ))

                  // validate aadhar number
                if(temp_num.includes(aadhar)){

                    addDoc(ref,{
                        Aadhar_No:aadhar,
                        Party_Vote:part1,
                        
                    }).then(()=>{
                        swal({
                            title: "Thank you for your vote",
                            icon: "success",
                            button: "Okay",
                          });
                         
                        const ref11= collection(db, "Counts");
                        
                        const getData1 =async ()=>{
                            
                            const data= await getDocs(ref11)
                            // console.table(data)
                            .then((snaps)=>{
                                snaps.forEach((doc_c)=>{
                                    counts.push({...doc_c.data(),id:doc_c.id})
                                })
                                // console.table(counts[0].Others);

                                 Count_1=counts[0].Party1;
                                 Count_2=counts[0].Party2;
                                 Count_3=counts[0].Party3;
                                 Count_4=counts[0].Party4;
                                 Count_o=counts[0].Others;

                                if(part1=="Party1"){
                                    Count_1=Count_1+1;
                                }
                                else if(part1=="Party2"){
                                    Count_2=Count_2+1;
                                }
                                else if(part1=="Party3"){
                                    Count_3=Count_3+1;
                                
                                }
                                else if(part1=="Party4"){
                                    Count_4=Count_4+1;
                                }
                                else{
                                    Count_o=Count_o+1;
        
                                }
                            })

                             // update counts of vote
                            const washingtonRef = doc(db, "Counts", "dtJD26qPgGfjx03WZJFG");
                            updateDoc(washingtonRef, {

                                Party1: Count_1,
                                Party2: Count_2,
                                Party3: Count_3,
                                Party4: Count_4,
                            })

                        }
                        getData1();

                   
                    })
                    .catch(err =>{
                        swal({
                            title: "Please Try Again",
                            icon: "error",
                            button: "Okay",
                          });
                    })
                }
                else{
                    swal({
                        title: "Please Enter Valid Aadhar Number",
                        icon: "warning",
                        button: "Okay",
                      });
                }
            })
            .catch(err =>{
                console.log(err.message)
            })
        }
        getData();
        const ref= collection(db, "Votes");


        setAadhar("")
        setParty1("")
        // signOut(auth);
         
      }
    return (<>
    <div className="bg-dash">
    <div className="container ">
        <div className="row g-2">
        <div className="col-8"> 
            <p className="display-6">E-Vote System</p>
        </div>
        <div className="col-4 "> 
            <button onClick={handleSignOut} className="btn btn-primary m-2">Sign Out</button>
            <Link to="/result" className="btn btn-primary m-2">View Result</Link>
        </div>
    </div>


    <form className="center loginform" onSubmit={handleVote}>

    <div className="form-check">
            <input className="form-check-input h4" type="radio" name="flexRadioDefault" id="flexRadioDefault1" 
             value="Party1"
             onChange={(e)=> setParty1(e.target.value)}
            />
        <img src={bjp} className="party-img" />
        <label className="form-check-label h4" htmlFor="flexRadioDefault1">
            BJP
        </label>
        </div>
        <div className="form-check">
            <input className="form-check-input h4" type="radio" name="flexRadioDefault" id="flexRadioDefault1" 
             value="Party2"
             onChange={(e)=> setParty1(e.target.value)}
            />
        <img src={ncp} className="party-img" />
        <label className="form-check-label h4" htmlFor="flexRadioDefault1">
            NCP
        </label>
        </div>
        <div className="form-check">
            <input className="form-check-input h4" type="radio" name="flexRadioDefault" id="flexRadioDefault1" 
             value="Party3"
             onChange={(e)=> setParty1(e.target.value)}
            />
        <img src={inc} className="party-img" />
        <label className="form-check-label h4" htmlFor="flexRadioDefault1">
            INC
        </label>
        </div>
        <div className="form-check">
            <input className="form-check-input h4" type="radio" name="flexRadioDefault" id="flexRadioDefault1" 
             value="Party4"
             onChange={(e)=> setParty1(e.target.value)}
            />
        <img src={aap} className="party-img" />
        <label className="form-check-label h4" htmlFor="flexRadioDefault1">
            AAP
        </label>
        </div>

        <div className="mb-3 row">
            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Enter Aadhar Number:</label>
            <div className="col-sm-10">
            <input type="name" className="form-control" id="inputPassword"
             value={aadhar} required
             onChange={(e)=> setAadhar(e.target.value)}
            />
        </div>
        
        </div>
            
        <button type="submit" className="btn btn-primary " >Submit</button>  
    </form>

        

        </div>
        </div>
    </>)
}
export default Dashboard;