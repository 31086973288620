import { Component} from "react";
import { useState,useEffect } from "react";
import { getAuth, signInWithEmailAndPassword,signOut, onAuthStateChanged,signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import swal from 'sweetalert';
import { app } from "../Firebase/auth"
import { Link,useNavigate } from "react-router-dom";
import { doc, setDoc,onSnapshot,getFirestore,getDocs, addDoc,collection ,updateDoc, query, where} from "firebase/firestore"; 
import Data from "../Components/data.component";

const Result =() =>
{
    let navigate = useNavigate();

    const auth = getAuth();
    const user = auth.currentUser; 

      //used to check user logged in or not
      onAuthStateChanged(auth, (user) => {
        if (user) {
            
        } else {
            swal({
                title: "Buddy,login first",
                text: "Please login",
                icon: "warning",
                button: "Okay",
              });
            navigate(`/`);
        }
      });



    
        return (<>

        <Data/>
        
        </>);
    
}
 
export default Result;