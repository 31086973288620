import Header from "./Components/header.component";
import {Component} from "react"
class Home extends Component {
    render() { 
        return (<>
        <Header/>
        </>);
    }
}
 
export default Home;