import {Component} from "react";
import Lottie from "lottie-react";
import vote from "../Animation/8174-vote.json"
import Data from "./data.component";
import { Link } from "react-router-dom";

class Header extends Component {

    render() { 
        return (<> 
        
        <div className="container mt-0 text-center">
            <p className="h1">E-Vote</p>
            <div className="row g-2">
                <div className="col-sm">
                    <Lottie loop={true} animationData={vote} />
                </div>
                <div className="col-sm v-cen">
                        {/* <Data/> */}
                        <p className="h1">All of us may have been created equal. But we'll never actually be equal until we all vote. So don't wait.</p>
                        <Link to="/login" className="btn btn-primary m-2"> Log In</Link>
                        <Link to="/register" className="btn btn-primary m-2"> Register</Link>
                </div>
            </div>
        </div>
       

        </>);
    }
}
 
export default Header;