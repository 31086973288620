import {Component} from "react";
import { Link,useNavigate } from "react-router-dom";
import logo from "../Assets/logo.png"
class Navbar extends Component {
    state = {  } 
    render() { 
        return (<>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow-lg p-3 mb-5  rounded">
                <div className="container-fluid">
                    <img src={logo} className="img-fluid logo"/>
                    <a className="navbar-brand" href="/">E-Vote</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="/">Home</a>
                        </li>
                            <li className="nav-item">
                                <Link className="nav-link active" to="/login">Login</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" to="/register">Create Account</Link>
                            </li>
                    </ul>
                    </div>
                </div>
                </nav>
        </>);
    }
}
 
export default Navbar;