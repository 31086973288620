import { doc, setDoc,getFirestore,getDocs, addDoc,collection ,updateDoc} from "firebase/firestore"; 
import bjp from "../Assets/bjp.png"
import ncp from "../Assets/ncp.png"
import inc from "../Assets/inc.jpeg"
import aap from "../Assets/aap.jpeg"
import { useState,useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";

const Data =()=>{
    const db=getFirestore();
    const [snaps, setSnaps] = useState([]);

    const ref1= collection(db, "Counts");

    useEffect(() => {
        // get party vote counts
        const getData =async ()=>{
            const data= await getDocs(ref1)
            // console.table(data)
            setSnaps(data.docs.map( (doc)=>({
                ...doc.data(),id:doc.id,
            }) ))
        }
        getData();
    },[]);
    
return(
    <>
    <div className="container ">
    <p className="h1">Voting Stats</p>
        <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
            <div className="col">
            <div className="p-3 border c-bg">
            {snaps.map((snap)=>{
                    return(
                        <div className="col ">
                            {" "}
                            <img src={bjp} className="party-img" />
                            <p className="display-6">BJP</p>
                            <p className="h3">{snap.Party1}</p>
                         </div>
                    )
                })}
             </div>
            </div>
            <div className="col">
            <div className="p-3 border c-bg">
            {snaps.map((snap)=>{
                    return(
                        <div className="col ">
                            {" "}
                            <img src={ncp} className="party-img" />
                            <p className="display-6">NCP</p>
                            <p className="h3">{snap.Party2}</p>
                         </div>
                    )
                })}
             </div>
            </div>
            <div className="col">
            <div className="p-3 border c-bg">
            {snaps.map((snap)=>{
                    return(
                        <div className="col ">
                            {" "}
                            <img src={inc} className="party-img" />
                            <p className="display-6">INC</p>
                            <p className="h3">{snap.Party3}</p>
                         </div>
                    )
                })}
             </div>
            </div>
            <div className="col">
            <div className="p-3 border c-bg">
            {snaps.map((snap)=>{
                    return(
                        <div className="col ">
                            {" "}
                            <img src={aap} className="party-img" />
                            <p className="display-6">AAP</p>
                            <p className="h3">{snap.Party4}</p>
                         </div>
                    )
                })}
             </div>
            </div>
        </div>
            {/* <Link to="/login" className="btn btn-primary m-2"> Log In</Link>
            <Link to="/register" className="btn btn-primary m-2"> Register</Link> */}
        </div>
    </>
)

}
export default Data;