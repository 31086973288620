// import CheckUser from "./check.admin"
import { useState,useEffect } from "react";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged,signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import swal from 'sweetalert';
import { app } from "../Firebase/auth"
import { Link,useNavigate } from "react-router-dom";

const Login = () =>{
    const [email, setEmail] = useState('');
    const [psw, setPsw] = useState('');

    let navigate = useNavigate(); // to navigate to
    const auth = getAuth();

//login function
    const handleLogin = (e) =>{
        e.preventDefault();
        const authentication = getAuth();
        signInWithEmailAndPassword(authentication, email, psw)
            .then((response) => {
               
                swal({
                    title: "Login  Succcesfully",
                    text: "Welcome",
                    icon: "success",
                    button: "Okay",
                  });
                  navigate(`/dashboard`);
                
          })
          .catch((error) => {
                    swal({
                        title: "Failed to sign in",
                        text: "please try again",
                        icon: "error",
                        button: "Okay",
                      });
           
        });
        setPsw("");
        setEmail("");
    }
    return(<>
        <div className="container text-center page-bg">
            <p className="h4 pl-4 white">Log In</p>
            <form className="center loginform" onSubmit={handleLogin}>
            <div className="form-floating mb-3">
                <input type="email" className="form-control" id="floatingInput" placeholder="abc"
                value={email} required
                onChange={(e)=> setEmail(e.target.value)}
                />
                <label htmlFor="floatingInput ">Email Address</label>
            </div>
            <div className="form-floating mb-3">
                <input type="password" className="form-control" id="floatingInput" placeholder="abc"
                value={psw} required
                onChange={(e)=> setPsw(e.target.value)}
                />
                <label htmlFor="floatingInput">Password</label>
            </div>
            <button type="submit" className="btn btn-primary" >Login</button>
            {/* <p>Forgot your password?</p> */}
            <p className="white">Don't have an account?
            <Link to="/register" className="white"> Sign up</Link></p>

            <Link to="/forgot" className="white">Forgot password ?</Link>
        </form>
        </div>
    </>)

}
export default Login;