import './App.css';
import Navbar from './Components/navbar.component';
import {BrowserRouter as Router, Routes, Route,} from 'react-router-dom';
import Home from './Home';
import Register from './backend/register.backend';
import Dashboard from './backend/dashboard.backend';
import Login from './backend/login.backend';
import Forgot from './backend/forgot.backend';
import Result from './backend/result.backend';

function App() {
  return (
    <div className="App">
      <Router>
          <Navbar/>
          <Routes>
            <Route path="/" exact element={<Home/>}/>
            <Route path="/register" element={<Register/> }/>
            <Route path="/login" element={<Login/> }/>
            <Route path="/forgot" element={<Forgot/> }/>
            <Route path="/dashboard" element={<Dashboard/> }/>
            <Route path="/result" element={<Result/> }/>
          </Routes>
     </Router>
    </div>
  );
}

export default App;
